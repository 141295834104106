import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { medWrapper, buttonThree } from "../../styles/helpers"
import GallerySlider from "./GallerySlider"

const GallerySection = styled.section`
  padding-top: 5rem;
  padding-bottom: 5rem;

  .wrapper {
    ${medWrapper};
  }

  .galleyWrap {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 1rem;
    grid-auto-rows: minmax(250px, auto);
    grid-auto-flow: dense;

    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-auto-rows: minmax(350px, auto);
    }

    @media (min-width: 1025px) {
      padding-bottom: 15rem;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      grid-auto-rows: minmax(350px, auto);
    }
  }

  .galleryImage {
    display: inline-block;
    width: 100%;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
  }

  .goBack {
    width: 100%;
    padding: 5rem 2rem 0;
    text-align: center;

    a {
      ${buttonThree};
    }
  }

  @for $i from 1 through 36 {
    &:nth-child(#{$i}) {
      $h: (random(400) + 100) + px;
      height: $h;
    }
  }
`

const HomeGallery = ({ gallery }) => {
  const images = gallery.acf._coo_hopst_gallery
  const [isSliderActive, setIsSliderActive] = useState(false)
  const [clikcedImage, setClikcedImage] = useState(0)

  return (
    <GallerySection>
      {images && (
        <>
          <div className="gallerySection">
            <div className="wrapper">
              <div className="galleyWrap">
                {images &&
                  images.length > 0 &&
                  images.map((image, index) => {
                    const imgFluid = image.localFile
                      ? image.localFile.childImageSharp
                        ? image.localFile.childImageSharp.fluid
                        : false
                      : false
                    const imgAlt = image.alt_text

                    return (
                      <div
                        className="galleryImage"
                        key={index}
                        onClick={() => {
                          setIsSliderActive(true)
                          setClikcedImage(index)
                        }}
                      >
                        <Img fluid={imgFluid} alt={imgAlt} />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>

          {isSliderActive && (
            <GallerySlider
              images={images}
              setIsSliderActive={setIsSliderActive}
              clikcedImage={clikcedImage}
            />
          )}
        </>
      )}
      <div className="goBack">
        <Link to="/pick-your-home/#perfectHome">go back to all homes</Link>
      </div>
    </GallerySection>
  )
}

export default HomeGallery
