import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import {
  medWrapper,
  colors,
  standardWrapper,
  H2Black,
  B1Black,
  H2Green,
  buttonThree,
} from "../../styles/helpers"
import { Link } from "gatsby"

const costAverager = cos => {
  let stratingFrom = ""
  if (cos <= 374) {
    stratingFrom = "mid $300"
  } else if (cos > 374 && cos <= 399) {
    stratingFrom = "high $300"
  } else if (cos > 399 && cos <= 424) {
    stratingFrom = "low $400"
  } else if (cos > 424 && cos <= 474) {
    stratingFrom = "mid $400"
  } else if (cos > 474 && cos <= 499) {
    stratingFrom = "high $400"
  } else if (cos > 499 && cos <= 524) {
    stratingFrom = "low $500"
  } else if (cos > 524 && cos <= 574) {
    stratingFrom = "mid $500"
  } else if (cos > 574 && cos <= 599) {
    stratingFrom = "high $500"
  } else if (cos > 599 && cos <= 624) {
    stratingFrom = "low $600"
  } else if (cos > 624 && cos <= 674) {
    stratingFrom = "mid $600"
  } else if (cos > 674 && cos <= 699) {
    stratingFrom = "high $600"
  } else if (cos > 699) {
    stratingFrom = "$700"
  }

  return stratingFrom
}

const HeaderIntroSection = styled.section`
  .homeUpper {
    width: 100%;
    background-color: ${colors.white};
  }

  .wrapper {
    ${medWrapper};
  }

  .homeDetails {
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 5rem;
    text-align: center;

    h3 {
      ${H2Black};
    }
  }

  .homeImage {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(45%);
      padding: 0 4rem;
    }
  }

  .homeContent {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(55%);
    }

    &__starting {
      margin-top: 2.5rem;
      @media (min-width: 768px) {
        margin-top: 0;
      }
      p {
        ${H2Green};
      }
    }

    &__description {
      p {
        ${B1Black};
      }
    }
  }

  .homeLower {
    width: 100%;
    padding: 3rem 0;

    &__wrapper {
      ${standardWrapper};
    }

    &__button {
      margin: 1rem auto;
      @media (min-width: 768px) {
        margin: auto 2rem;
      }
      a {
        ${buttonThree};
      }
    }
  }
`

const HeaderIntro = ({ data, types, details, costs, builders, location }) => {
  const startingFrom = costs.edges.find(
    cost => cost.node.wordpress_id === data.cost[0]
  )

  const homeType = types.edges.find(
    type => type.node.wordpress_id === data.home_type[0]
  )

  const detailsList = details.edges.filter(detail => {
    const foundDetail = data.home_details.find(homdet => {
      return detail.node.wordpress_id === homdet
    })

    return foundDetail
  })

  const homeBuilder = builders.edges.find(
    builder => builder.node.wordpress_id === data.builder[0]
  )

  const builderLink =
    homeBuilder.node.wordpress_id === 15 || homeBuilder.node.wordpress_id === 25

  const stratingRange = startingFrom?.node?.name ? costAverager(startingFrom.node.name) : null

  console.log("location", location)
  console.log("do not display 1", location === "/pick-your-home/attached-vesta-properties-townhouse" || location === "/pick-your-home/attached-vesta-properties-townhouse/")
  console.log("do not display 2", location === "/pick-your-home/attached-homes-vesta-properties-apartments" || location === "/pick-your-home/attached-homes-vesta-properties-apartments/")
  console.log("do not display 1 or 2", location === "/pick-your-home/attached-vesta-properties-townhouse" || location === "/pick-your-home/attached-vesta-properties-townhouse" || location === "/pick-your-home/attached-vesta-properties-townhouse/" || location === "/pick-your-home/attached-homes-vesta-properties-apartments/")
  
  const donotdisplay = location === "/pick-your-home/attached-vesta-properties-townhouse" || location === "/pick-your-home/attached-homes-vesta-properties-apartments" || location === "/pick-your-home/attached-vesta-properties-townhouse/" || location === "/pick-your-home/attached-homes-vesta-properties-apartments/"
  console.log("donotdisplay", donotdisplay)
  
  const isBYOB =
    location === "/pick-your-home/estate-homes-byob-coopers-landing"
      ? true
      : false

  return (
    <HeaderIntroSection>
      <div className="homeUpper">
        <div className="wrapper">
          <div className="homeDetails">
            <h3>
              <span>{homeType.node.name} | </span>
              {detailsList.map(det => {
                return (
                  <span key={det.node.wordpress_id}>{det.node.name} | </span>
                )
              })}
              <span>{homeBuilder.node.name}</span>
            </h3>
          </div>
          <div className="homeImage">
            <Img
              fluid={
                data?.acf?._coo_hopst_home_image?.localFile?.childImageSharp
                  ?.fluid
              }
              alt={data?.acf?._coo_hopst_home_image?.alt_text}
            />
          </div>
          <div className="homeContent">
            <div className="homeContent__starting">
              {/* <p>Starting from the {stratingRange}s</p> */}
              {isBYOB ? (
                <p>
                  All lots designated for direct sale from the developer have
                  been sold
                </p>
              ) : (
                <p>
                  <Link to="/show-homes">Contact Show Homes for Pricing</Link>
                </p>
              )}
            </div>
            <div
              className="homeContent__description"
              dangerouslySetInnerHTML={{
                __html: data.acf._coo_hopst_homes_content,
              }}
            />
          </div>
        </div>
      </div>
      <div className="homeLower">
        <div className="homeLower__wrapper">
          {!donotdisplay && 
           <div className="homeLower__button">
              <Link to={`/pick-your-home/pick-your-lot`}>Pick Your Lot</Link>
           </div>
          }
          
          {!builderLink && (
            <>
              <div className="homeLower__button">
                <Link to={`/show-homes`}>Show Homes</Link>
              </div>
              <div className="homeLower__button">
                <Link href={`/show-homes/builders-info`}>Builder Info</Link>
              </div>
            </>
          )}
        </div>
      </div>
    </HeaderIntroSection>
  )
}

export default HeaderIntro
