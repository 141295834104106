import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import HeaderIntro from "../components/SingleHome/HeaderIntro"
import HomeGallery from "../components/SingleHome/HomeGallery"

const SingleHome = props => {
  const {
    home,
    allTypes,
    allDetails,
    allCost,
    allBuilders,
    homeGallery,
  } = props.data
  const location = props.location.pathname

  const newTitle = props.data.home.title
    .split("&#8211;")
    .map(word => word.trim())
    .join(" ")

  return (
    <Layout location={location}>
      <SEO title={newTitle} />
      <HeaderIntro
        data={home}
        types={allTypes}
        details={allDetails}
        costs={allCost}
        builders={allBuilders}
        location={location}
      />
      <HomeGallery gallery={homeGallery} />
    </Layout>
  )
}

export const query = graphql`
  query singleHomeQuery($slug: String!) {
    home: wordpressWpHomes(slug: { eq: $slug }) {
      wordpress_id
      title
      home_type
      home_details
      builder
      cost
      slug
      acf {
        _coo_hopst_homes_content
        _coo_hopst_home_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    allDetails: allWordpressWpHomeDetails {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allCost: allWordpressWpCost {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allBuilders: allWordpressWpBuilder {
      edges {
        node {
          wordpress_id
          name
        }
      }
    }
    allTypes: allWordpressWpHomeType {
      edges {
        node {
          name
          wordpress_id
        }
      }
    }

    homeGallery: wordpressWpHomes(slug: { eq: $slug }) {
      acf {
        _coo_hopst_gallery {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
export default SingleHome
